import {
  ComponentDefinition,
  ResponsiveLayout,
} from '@wix/platform-editor-sdk';
import { TFunction } from 'i18next';

export const popupCloseIconButton = (isResponsive: boolean) => ({
  type: 'Component',
  skin: 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c',
  layout: {
    width: 35,
    height: 35,
    scale: 1,
    rotationInDegrees: 0,
    anchors: [],
    fixedPosition: true,
    x: 685,
    y: 20,
  },
  componentType: 'wysiwyg.viewer.components.PopupCloseIconButton',
  props: {
    type: 'SvgShapeProperties',
    maintainAspectRatio: true,
  },
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'WixCodeConnectionItem',
        role: 'closeIcon',
      },
      {
        type: 'ConnectionItem',
        role: 'closeIcon',
        isPrimary: true,
        controllerId: 'dataItem-kdy6319y',
      },
    ],
  },
  style: {
    type: 'TopLevelStyle',
    style: {
      groups: {},
      properties: {
        fillcolor: '#000',
      },
      propertiesSource: {
        fillcolor: 'value',
      },
    },
    componentClassName: 'wysiwyg.viewer.components.PopupCloseIconButton',
    styleType: 'custom',
    skin: 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c',
  },
  mobileStructure: {
    layout: {
      width: 18,
      height: 18,
      x: 19,
      y: 17,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  },
  layouts: isResponsive
    ? {
        componentLayout: {
          type: 'ComponentLayout',
          height: {
            type: 'auto',
          },
          width: {
            type: 'px',
            value: 18,
          },
          minWidth: {
            type: 'px',
            value: 0,
          },
          maxWidth: {
            type: 'px',
            value: 99999,
          },
          minHeight: {
            type: 'px',
            value: 18,
          },
        },
        itemLayout: {
          type: 'GridItemLayout',
          margins: {
            left: {
              type: 'px',
              value: 0,
            },
            right: {
              type: 'px',
              value: 25,
            },
            top: {
              type: 'px',
              value: 25,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 1,
            columnStart: 1,
            rowEnd: 2,
            columnEnd: 2,
          },
          alignSelf: 'start',
          justifySelf: 'end',
        },
        containerLayout: {},
        type: 'SingleLayoutData',
      }
    : undefined,
});

const title = (t: TFunction, isResponsive: boolean): ComponentDefinition => {
  return {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout: {
      width: 300,
      height: 43,
      x: 83.5,
      y: 53,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.WRichText',
    parent: 'comp-kdy631a0',
    data: {
      type: 'StyledText',
      text: isResponsive
        ? `<h1 class="font_0"><span>${t('components.title.label')}</span></h1>`
        : `<h1 class="font_0" style="font-size: 40px; text-align: center;"><span style="font-size:40px;"><span class="color_15">${t(
            'components.title.label',
          )}</span></span></h1>`,
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      linkList: [],
    },
    props: {
      type: 'WRichTextProperties',
      brightness: 1,
      packed: true,
      verticalText: false,
    },
    // @ts-expect-error
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'title',
        },
        {
          type: 'ConnectionItem',
          role: 'title',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? {
          type: 'ComponentStyle',
          style: {
            properties: {
              f7:
                'normal normal normal 15px/1.4em helvetica-w01-light {color_15}',
              f6: 'normal normal normal 19px/1.4em Open+Sans {color_14}',
              f1: 'normal normal normal 13px/1.4em arial+black {color_11}',
              f8:
                'normal normal normal 13px/1.4em helvetica-w01-light {color_15}',
              f10: 'normal normal normal 10px/1.4em Arial {color_15}',
              f0: 'normal normal normal 45px/1.4em arial+black {color_14}',
              f5: 'normal normal normal 22px/1.4em Open+Sans {color_14}',
              textAlign: 'center',
              f3: 'normal normal normal 40px/1.4em arial+black {color_14}',
              f9: 'normal normal normal 12px/1.4em Arial {color_15}',
              f4: 'normal normal normal 30px/1.4em sacramento {color_14}',
              fontSize: '30px',
              f2: 'normal normal normal 26px/1.4em arial+black {color_14}',
            },
            propertiesSource: {
              f7: 'value',
              f6: 'value',
              f1: 'value',
              f8: 'value',
              f10: 'value',
              f0: 'value',
              f5: 'value',
              textAlign: 'value',
              f3: 'value',
              f9: 'value',
              f4: 'value',
              fontSize: 'value',
              f2: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        }
      : 'txtNew',
    mobileStructure: {
      layout: {
        width: 280,
        height: 36,
        x: 10,
        y: 59,
        scale: 1.1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'WRichTextProperties',
        brightness: 1,
        packed: true,
        verticalText: false,
        id: 'mobile_propItem-kdy631an',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            minHeight: {
              type: 'px',
              value: 0,
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'percentage',
              value: 100,
            },
          },
          itemLayout: {
            alignSelf: 'start',
            margins: {
              left: {
                value: 0,
                type: 'percentage',
              },
              right: {
                type: 'percentage',
                value: 0,
              },
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const signUpLink = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition => {
  return {
    type: 'Component',
    id: 'comp-ke10phlg1',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout: {
      x: 83.5,
      y: 110,
      fixedPosition: false,
      width: 300,
      height: 20,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: 'wysiwyg.viewer.components.WRichText',
    data: {
      type: 'StyledText',
      text: isResponsive
        ? `<p class="font_8"><span>${t(
            'components.subTitle.label',
          )} <span style="text-decoration:underline" class="link" tabindex="0">${t(
            'components.signUpLink.label',
          )}</span></span></p>`
        : `<p class="font_8" style="font-size: 16px; line-height: 1.3em; text-align: center;"><span style="font-size:16px;"><span class="color_15" style="letter-spacing:0.03em">${t(
            'components.subTitle.label',
          )} <span style="text-decoration:underline" class="link" tabindex="0">${t(
            'components.signUpLink.label',
          )}</span></span></span></p>`,
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      linkList: [],
    },
    props: {
      type: 'WRichTextProperties',
      brightness: 1,
      packed: true,
      minWidth: null,
      verticalText: false,
    },
    // @ts-expect-error
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'signUpLink',
        },
        {
          type: 'ConnectionItem',
          role: 'signUpLink',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? {
          type: 'ComponentStyle',
          style: {
            properties: {
              fontSize: '17px',
              lineHeight: '1.6em',
              textAlign: 'center',
            },
            propertiesSource: {
              fontSize: 'value',
              lineHeight: 'value',
              textAlign: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        }
      : 'txtNew',
    mobileStructure: {
      layout: {
        width: 260,
        height: 20,
        x: 20,
        y: 114,
        scale: 1.0666666666666667,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'WRichTextProperties',
        brightness: 1,
        packed: true,
        verticalText: false,
        id: 'mobile_propItem-ke10phlh1',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            type: 'ComponentLayout',
            height: {
              type: 'auto',
            },
            width: {
              type: 'percentage',
              value: 100,
            },
            hidden: false,
          },
          itemLayout: {
            alignSelf: 'end',
            margins: {
              left: {
                value: 0,
                type: 'percentage',
              },
              right: {
                type: 'percentage',
                value: 0,
              },
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const email = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition & { connections: any } => {
  const placeholder = t('components.email.label');
  return {
    type: 'Component',
    skin: isResponsive
      ? 'wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin'
      : 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
    layout: {
      x: 83.5,
      y: 156,
      fixedPosition: false,
      width: 300,
      height: 66,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: 'wysiwyg.viewer.components.inputs.TextInput',
    data: {
      type: 'TextInput',
      value: '',
      textType: 'email',
      label: placeholder,
      placeholder,
    },
    props: {
      type: 'TextInputProperties',
      required: true,
      inputHeight: 42,
      inputHeightMobile: 44,
      labelMargin: 8,
      labelPadding: 0,
      textAlignment: 'left',
      textPadding: 12,
      placeholder,
      defaultTextType: 'placeholderText',
    },
    behaviors: {
      type: 'ObsoleteBehaviorsList',
      items: '[]',
    },
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'email',
        },
        {
          type: 'ConnectionItem',
          role: 'email',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? {
          type: 'ComponentStyle',
          style: {
            propertiesOverride: {},
            properties: {
              'alpha-bgf': '1',
              'alpha-btn_brd': '0.55',
              bgh: 'color_11',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              'alpha-bgd': '1',
              rd: '0px',
              btn_brd: 'color_15',
              txtd: '#DBDBDB',
              btn_fnt: 'font_8',
              'alpha-bge': '1.0',
              bge: 'color_11',
              'alpha-brdh': '1',
              fnt2: 'font_8',
              'alpha-brd': '1',
              fntlbl: 'font_8',
              'alpha-bg': '1',
              bg: 'color_11',
              txt2: '#373B4D',
              btn_brw: '1',
              txtlbl: '#373B4D',
              txt: '#000000',
              bgf: 'color_11',
              txtlblrq: '#373B4D',
              'alpha-bgh': '1',
              'alpha-brde': '1',
              brwh: '2px',
              'txt-placeholder': 'color_14',
              brw: '1px',
              brwd: '1',
              'alpha-brdf': '1',
              fnt: 'font_8',
              brd: 'color_15',
              brdf: 'color_15',
              'boxShadowToggleOn-shd': 'false',
              brdd: '#DBDBDB',
              brwe: '2px',
              bgd: '#FFFFFF',
              brdh: '#373B4D',
              brde: '#FF4040',
              brwf: '2px',
            },
            propertiesSource: {
              'alpha-bgf': 'value',
              'alpha-btn_brd': 'value',
              bgh: 'theme',
              shd: 'value',
              'alpha-bgd': 'value',
              rd: 'value',
              btn_brd: 'theme',
              txtd: 'value',
              btn_fnt: 'theme',
              'alpha-bge': 'value',
              bge: 'theme',
              'alpha-brdh': 'value',
              fnt2: 'theme',
              'alpha-brd': 'value',
              fntlbl: 'theme',
              'alpha-bg': 'value',
              bg: 'theme',
              txt2: 'value',
              btn_brw: 'value',
              txtlbl: 'value',
              txt: 'value',
              bgf: 'theme',
              txtlblrq: 'value',
              'alpha-bgh': 'value',
              'alpha-brde': 'value',
              brwh: 'value',
              'txt-placeholder': 'theme',
              brw: 'value',
              brwd: 'value',
              'alpha-brdf': 'value',
              fnt: 'theme',
              brd: 'theme',
              brdf: 'theme',
              'boxShadowToggleOn-shd': 'value',
              brdd: 'value',
              brwe: 'value',
              bgd: 'value',
              brdh: 'value',
              brde: 'value',
              brwf: 'value',
            },
          } as any,
          componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin',
        }
      : {
          type: 'ComponentStyle',
          style: {
            properties: {
              'alpha-bg': '1',
              'alpha-bge': '1',
              'alpha-bgf': '1',
              'alpha-bgh': '1',
              'alpha-brd': '1',
              'alpha-brde': '1',
              'alpha-brdf': '1',
              'alpha-brdh': '1',
              bg: 'color_11',
              bgd: '#FFFFFF',
              bge: 'color_11',
              bgf: 'color_11',
              bgh: 'color_11',
              'boxShadowToggleOn-shd': 'false',
              brd: 'color_15',
              brdd: '#DBDBDB',
              brde: '#FF4040',
              brdf: 'color_15',
              brdh: 'color_15',
              brw: '1px',
              brwe: '1px',
              brwf: '2px',
              brwh: '2px',
              fnt: 'font_8',
              fntlbl: 'font_8',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              txt: 'color_15',
              txt2: 'color_14',
              txtd: '#DBDBDB',
              txtlbl: 'color_15',
              txtlblrq: 'color_14',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-bge': 'value',
              'alpha-bgf': 'value',
              'alpha-bgh': 'value',
              'alpha-brd': 'value',
              'alpha-brde': 'value',
              'alpha-brdf': 'value',
              'alpha-brdh': 'value',
              bg: 'theme',
              bgd: 'value',
              bge: 'theme',
              bgf: 'theme',
              bgh: 'theme',
              'boxShadowToggleOn-shd': 'value',
              brd: 'theme',
              brdd: 'value',
              brde: 'value',
              brdf: 'theme',
              brdh: 'theme',
              brw: 'value',
              brwe: 'value',
              brwf: 'value',
              brwh: 'value',
              fnt: 'theme',
              fntlbl: 'theme',
              rd: 'value',
              shd: 'value',
              txt: 'theme',
              txt2: 'theme',
              txtd: 'value',
              txtlbl: 'theme',
              txtlblrq: 'theme',
            },
          },
          componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
        },
    mobileStructure: {
      layout: {
        width: 260,
        height: 44,
        x: 20,
        y: 170,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'TextInputProperties',
        required: true,
        inputHeight: 37,
        inputHeightMobile: 44,
        labelMargin: 14,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 14,
        placeholder,
        defaultTextType: 'placeholderText',
        id: 'mobile_propItem-kdy631b3',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            minHeight: {
              value: 60,
              type: 'px',
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'percentage',
              value: 100,
            },
            maxWidth: {
              type: 'px',
              value: 290,
            },
          },
          itemLayout: {
            margins: {
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
              left: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 2,
              rowEnd: 3,
              columnStart: 1,
              columnEnd: 2,
            },
            id: '',
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const password = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition & { connections: any } => {
  const placeholder = t('components.password.label');
  return {
    type: 'Component',
    skin: isResponsive
      ? 'wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin'
      : 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
    layout: {
      x: 83.5,
      y: 244,
      fixedPosition: false,
      width: 300,
      height: 65,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: 'wysiwyg.viewer.components.inputs.TextInput',
    data: {
      placeholder,
      type: 'TextInput',
      value: '',
      textType: 'password',
      label: placeholder,
    },
    props: {
      type: 'TextInputProperties',
      required: true,
      inputHeight: 42,
      inputHeightMobile: 44,
      labelMargin: 8,
      labelPadding: 0,
      textAlignment: 'left',
      textPadding: 12,
      placeholder,
      defaultTextType: 'placeholderText',
    },
    behaviors: {
      type: 'ObsoleteBehaviorsList',
      items: '[]',
    },
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'password',
        },
        {
          type: 'ConnectionItem',
          role: 'password',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? ({
          type: 'ComponentStyle',
          style: {
            groups: {},
            properties: {
              'alpha-bgf': '1.0',
              bgh: 'color_11',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              rd: '0px',
              txtd: '#DBDBDB',
              'alpha-bge': '1.0',
              bge: 'color_11',
              'alpha-brdh': '1.0',
              'alpha-brd': '1.0',
              fntlbl: 'font_8',
              'alpha-bg': '1.0',
              bg: 'color_11',
              txt2: '#373B4D',
              txtlbl: '#373B4D',
              txt: '#000000',
              bgf: 'color_11',
              txtlblrq: '#373B4D',
              'alpha-bgh': '1.0',
              'alpha-brde': '1.0',
              brwh: '2px',
              brw: '1px',
              'alpha-brdf': '1.0',
              fnt: 'font_8',
              brd: 'color_15',
              brdf: 'color_15',
              'boxShadowToggleOn-shd': 'false',
              brdd: '#DBDBDB',
              brwe: '2px',
              bgd: '#FFFFFF',
              brdh: '#373B4D',
              brde: '#FF4040',
              brwf: '2px',
            },
            propertiesSource: {
              'alpha-bgf': 'value',
              bgh: 'theme',
              shd: 'value',
              rd: 'value',
              txtd: 'value',
              'alpha-bge': 'value',
              bge: 'theme',
              'alpha-brdh': 'value',
              'alpha-brd': 'value',
              fntlbl: 'theme',
              'alpha-bg': 'value',
              bg: 'theme',
              txt2: 'value',
              txtlbl: 'value',
              txt: 'value',
              bgf: 'theme',
              txtlblrq: 'value',
              'alpha-bgh': 'value',
              'alpha-brde': 'value',
              brwh: 'value',
              brw: 'value',
              'alpha-brdf': 'value',
              fnt: 'theme',
              brd: 'theme',
              brdf: 'theme',
              'boxShadowToggleOn-shd': 'value',
              brdd: 'value',
              brwe: 'value',
              bgd: 'value',
              brdh: 'value',
              brde: 'value',
              brwf: 'value',
            },
          },
          componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin',
        } as any)
      : {
          type: 'ComponentStyle',
          style: {
            properties: {
              'alpha-bg': '1',
              'alpha-bge': '1',
              'alpha-bgf': '1',
              'alpha-bgh': '1',
              'alpha-brd': '1',
              'alpha-brde': '1',
              'alpha-brdf': '1',
              'alpha-brdh': '1',
              bg: 'color_11',
              bgd: '#FFFFFF',
              bge: 'color_11',
              bgf: 'color_11',
              bgh: 'color_11',
              'boxShadowToggleOn-shd': 'false',
              brd: 'color_15',
              brdd: '#DBDBDB',
              brde: '#FF4040',
              brdf: 'color_15',
              brdh: 'color_15',
              brw: '1px',
              brwe: '1px',
              brwf: '2px',
              brwh: '2px',
              fnt: 'font_8',
              fntlbl: 'font_8',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              txt: 'color_15',
              txt2: 'color_14',
              txtd: '#DBDBDB',
              txtlbl: 'color_15',
              txtlblrq: 'color_14',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-bge': 'value',
              'alpha-bgf': 'value',
              'alpha-bgh': 'value',
              'alpha-brd': 'value',
              'alpha-brde': 'value',
              'alpha-brdf': 'value',
              'alpha-brdh': 'value',
              bg: 'theme',
              bgd: 'value',
              bge: 'theme',
              bgf: 'theme',
              bgh: 'theme',
              'boxShadowToggleOn-shd': 'value',
              brd: 'theme',
              brdd: 'value',
              brde: 'value',
              brdf: 'theme',
              brdh: 'theme',
              brw: 'value',
              brwe: 'value',
              brwf: 'value',
              brwh: 'value',
              fnt: 'theme',
              fntlbl: 'theme',
              rd: 'value',
              shd: 'value',
              txt: 'theme',
              txt2: 'theme',
              txtd: 'value',
              txtlbl: 'theme',
              txtlblrq: 'theme',
            },
          },
          componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
        },
    mobileStructure: {
      layout: {
        width: 260,
        height: 44,
        x: 20,
        y: 226,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'TextInputProperties',
        required: true,
        inputHeight: 37,
        inputHeightMobile: 44,
        labelMargin: 14,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 14,
        placeholder,
        defaultTextType: 'placeholderText',
        id: 'propItem-kdy631b81',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            minHeight: {
              value: 60,
              type: 'px',
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'percentage',
              value: 100,
            },
            maxWidth: {
              type: 'px',
              value: 290,
            },
          },
          itemLayout: {
            type: 'GridItemLayout',
            gridArea: {
              rowStart: 3,
              rowEnd: 4,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const forgotPasswordLink = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition => ({
  type: 'Component',
  skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
  layout: {
    width: 300,
    height: 18,
    x: 83.5,
    y: 318,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.WRichText',
  data: {
    type: 'StyledText',
    text: isResponsive
      ? `<p class="font_8">${t('components.forgotPasswordLink.label')}</p>`
      : `<p class="font_8" style="font-size: 14px; line-height: 1.3em;"><span style="font-size:14px;"><span class="color_15"><span style="letter-spacing:0.03em"><span style="text-decoration:underline" tabindex="0">${t(
          'components.forgotPasswordLink.label',
        )}</span></span></span></span></p>`,
    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    linkList: [],
  },
  props: {
    type: 'WRichTextProperties',
    brightness: 1,
    packed: true,
    verticalText: false,
  },
  // @ts-expect-error
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'WixCodeConnectionItem',
        role: 'forgotPasswordLink',
      },
      {
        type: 'ConnectionItem',
        role: 'forgotPasswordLink',
        isPrimary: true,
        controllerId: 'dataItem-kdy6319y',
      },
    ],
  },
  style: isResponsive
    ? {
        type: 'ComponentStyle',
        style: {
          properties: {
            fontSize: '18px',
            lineHeight: '1.6em',
            textAlign: 'center',
            textDecoration: 'underline',
          },
          propertiesSource: {
            fontSize: 'value',
            lineHeight: 'value',
            textAlign: 'value',
            textDecoration: 'value',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WRichText',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      }
    : 'txtNew',
  activeModes: {},
  mobileStructure: {
    layout: {
      width: 260,
      height: 20,
      x: 20,
      y: 296,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    props: {
      type: 'WRichTextProperties',
      brightness: 1,
      packed: true,
      verticalText: false,
    },
  },
  layouts: isResponsive
    ? ({
        containerLayout: {},
        componentLayout: {
          type: 'ComponentLayout',
          height: {
            type: 'auto',
          },
          width: {
            type: 'percentage',
            value: 100,
          },
          hidden: false,
        },
        itemLayout: {
          alignSelf: 'start',
          margins: {
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 0,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 4,
            rowEnd: 5,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'start',
          type: 'GridItemLayout',
        },
        type: 'SingleLayoutData',
      } as any)
    : undefined,
});

const generalErrMsg = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition => {
  return {
    type: 'Component',
    id: 'comp-kdy631bc2',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout: {
      width: 300,
      height: 18,
      x: 83.5,
      y: 349,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.WRichText',
    data: {
      type: 'StyledText',
      text: isResponsive
        ? `<p class="font_8">${t('components.general.errorPlaceholder')}</p>`
        : `<p class="font_8" style="font-size: 14px; line-height: 1.3em; text-align: center;"><span style="color:#FF3D3D"><span style="letter-spacing:0.03em">${t(
            'components.general.errorPlaceholder',
          )}</span></span></p>`,
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      linkList: [],
      id: 'dataItem-kdy631bd',
    },
    props: {
      type: 'WRichTextProperties',
      isHidden: false,
      brightness: 1,
      packed: true,
      minWidth: null,
      verticalText: false,
    },
    // @ts-expect-error
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'generalErrMsg',
        },
        {
          type: 'ConnectionItem',
          role: 'generalErrMsg',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? {
          type: 'ComponentStyle',
          style: {
            properties: {
              textDecoration: 'none',
              color: '#FF4040',
              textAlign: 'center',
              lineHeight: '1.2em',
              fontSize: '15px',
            },
            propertiesSource: {
              textDecoration: 'value',
              color: 'value',
              textAlign: 'value',
              lineHeight: 'value',
              fontSize: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        }
      : 'txtNew',
    mobileStructure: {
      layout: {
        width: 260,
        height: 36,
        x: 20,
        y: 318,
        scale: 0.9333333333333333,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'WRichTextProperties',
        isHidden: false,
        brightness: 1,
        packed: true,
        verticalText: false,
        id: 'mobile_propItem-kdy631bd1',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            type: 'ComponentLayout',
            height: {
              type: 'auto',
            },
            width: {
              type: 'percentage',
              value: 100,
            },
            hidden: false,
          },
          itemLayout: {
            alignSelf: 'start',
            margins: {
              left: {
                value: -0.00008878030072126397,
                type: 'percentage',
              },
              right: {
                type: 'percentage',
                value: 0,
              },
              top: {
                type: 'px',
                value: 32.203125,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 4,
              rowEnd: 5,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const submit = (t: TFunction, isResponsive: boolean): ComponentDefinition => {
  const label = t('components.submit.label');
  return {
    type: 'Component',
    skin: isResponsive
      ? 'wysiwyg.viewer.skins.button.WrappingButton'
      : 'wysiwyg.viewer.skins.button.BasicButton',
    layout: {
      width: 300,
      height: 46,
      x: 83.5,
      y: 405,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.SiteButton',
    data: {
      type: 'LinkableButton',
      label,
      link: { type: 'FormSubmitButtonLink' },
    },
    props: {
      type: 'ButtonProperties',
      align: 'center',
      margin: 0,
    },
    behaviors: {
      type: 'ObsoleteBehaviorsList',
      items: '[]',
    },
    // @ts-expect-error
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'submit',
        },
        {
          type: 'ConnectionItem',
          role: 'submit',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? ({
          type: 'ComponentStyle',
          style: {
            propertiesOverride: {
              fnt: {
                fontSize: '16px',
              },
            },
            properties: {
              'alpha-txth': '1',
              bgh: 'color_19',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              rd: '0px',
              'alpha-brdh': '0',
              txth: 'color_11',
              'alpha-brd': '0',
              'alpha-bg': '1',
              verticalPadding: '0',
              bg: 'color_15',
              txt: 'color_11',
              'alpha-bgh': '1',
              brw: '0',
              fnt: 'font_7',
              brd: 'color_14',
              'boxShadowToggleOn-shd': 'false',
              horizontalPadding: '0',
              'alpha-txt': '1',
              brdh: 'color_11',
            },
            propertiesSource: {
              'alpha-txth': 'value',
              bgh: 'theme',
              shd: 'value',
              rd: 'value',
              'alpha-brdh': 'value',
              txth: 'theme',
              'alpha-brd': 'value',
              'alpha-bg': 'value',
              verticalPadding: 'value',
              bg: 'theme',
              txt: 'theme',
              'alpha-bgh': 'value',
              brw: 'value',
              fnt: 'theme',
              brd: 'theme',
              'boxShadowToggleOn-shd': 'value',
              horizontalPadding: 'value',
              'alpha-txt': 'value',
              brdh: 'theme',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.SiteButton',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.button.WrappingButton',
        } as any)
      : {
          type: 'ComponentStyle',
          style: {
            properties: {
              'alpha-bg': '1',
              'alpha-bgh': '1',
              'alpha-brd': '1',
              'alpha-brdh': '0',
              'alpha-txt': '1',
              'alpha-txth': '1',
              bg: 'color_15',
              bgh: '#000000',
              'boxShadowToggleOn-shd': 'false',
              brd: 'color_15',
              brdh: 'color_11',
              brw: '0',
              fnt: 'font_8',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
              txt: 'color_11',
              txth: 'color_11',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-bgh': 'value',
              'alpha-brd': 'value',
              'alpha-brdh': 'value',
              'alpha-txt': 'value',
              'alpha-txth': 'value',
              bg: 'theme',
              bgh: 'value',
              'boxShadowToggleOn-shd': 'value',
              brd: 'theme',
              brdh: 'theme',
              brw: 'value',
              fnt: 'theme',
              rd: 'value',
              shd: 'value',
              txt: 'theme',
              txth: 'theme',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.SiteButton',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.button.BasicButton',
        },
    mobileStructure: {
      layout: {
        width: 260,
        height: 42,
        x: 20,
        y: 366,
        scale: 1.0666666666666667,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'ButtonProperties',
        align: 'center',
        margin: 0,
        id: 'propItem-kdy631bh',
      },
    },
    layouts: isResponsive
      ? ({
          containerLayout: {},
          componentLayout: {
            minHeight: {
              type: 'px',
              value: 50,
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'px',
              value: 180,
            },
          },
          itemLayout: {
            alignSelf: 'end',
            margins: {
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
              left: {
                type: 'percentage',
                value: 0,
              },
              right: {
                type: 'percentage',
                value: 0.00470632530120482,
              },
            },
            gridArea: {
              rowStart: 4,
              rowEnd: 5,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } as any)
      : undefined,
  };
};

const socialLabel = (
  t: TFunction,
  isResponsive: boolean,
): ComponentDefinition => {
  return {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout: {
      width: 300,
      height: 19,
      x: 83.5,
      y: 467,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.WRichText',
    data: {
      type: 'StyledText',
      text: isResponsive
        ? `<p class="font_9"><span>${t(
            'components.socialLabel.label',
          )}</span></p>`
        : `<p class="font_8" style="line-height:1.3em; text-align:center"><span class="color_15" style="letter-spacing:0.03em">${t(
            'components.socialLabel.label',
          )}</span></p>`,
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      linkList: [],
    },
    props: {
      type: 'WRichTextProperties',
      brightness: 1,
      packed: true,
      verticalText: false,
    },
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'socialDividerLabel',
        },
        {
          type: 'ConnectionItem',
          role: 'socialDividerLabel',
          isPrimary: true,
          controllerId: 'dataItem-kdy6319y',
        },
      ],
    },
    style: isResponsive
      ? {
          type: 'ComponentStyle',
          style: {
            properties: {
              f7:
                'normal normal normal 15px/1.4em helvetica-w01-light {color_15}',
              f6: 'normal normal normal 19px/1.4em Open+Sans {color_14}',
              f1: 'normal normal normal 13px/1.4em arial+black {color_11}',
              f8:
                'normal normal normal 13px/1.4em helvetica-w01-light {color_15}',
              f10: 'normal normal normal 10px/1.4em Arial {color_15}',
              f0: 'normal normal normal 45px/1.4em arial+black {color_14}',
              f5: 'normal normal normal 22px/1.4em Open+Sans {color_14}',
              textAlign: 'center',
              f3: 'normal normal normal 40px/1.4em arial+black {color_14}',
              f9: 'normal normal normal 12px/1.4em Arial {color_15}',
              f4: 'normal normal normal 30px/1.4em sacramento {color_14}',
              fontSize: '17px',
              f2: 'normal normal normal 26px/1.4em arial+black {color_14}',
            },
            propertiesSource: {
              f7: 'value',
              f6: 'value',
              f1: 'value',
              f8: 'value',
              f10: 'value',
              f0: 'value',
              f5: 'value',
              textAlign: 'value',
              f3: 'value',
              f9: 'value',
              f4: 'value',
              fontSize: 'value',
              f2: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        }
      : 'txtNew',
    mobileStructure: {
      layout: {
        width: 280,
        height: 10,
        x: 10,
        y: 417,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: 'WRichTextProperties',
        brightness: 1,
        packed: true,
        verticalText: false,
      },
    },
    activeModes: {},
    layouts: isResponsive ? {
      containerLayout: {} as any,
      componentLayout: {
        id: '',
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
        width: {
          type: 'percentage',
          value: 50,
        },
        hidden: false,
      },
      itemLayout: {
        id: '',
        alignSelf: 'start',
        margins: {
          left: {
            type: 'percentage',
            value: 0,
          },
          right: {
            type: 'percentage',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        gridArea: {
          rowStart: 5,
          rowEnd: 6,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
      // imported type doesn't match the actually required type
      // @ts-expect-error
      type: 'SingleLayoutData',
    } : undefined,
  };
};

const socialDivider = (t: TFunction): ComponentDefinition => {
  return {
    type: 'Container',
    skin: 'wysiwyg.viewer.components.GroupSkin',
    layout: {
      x: 148,
      y: 433,
      fixedPosition: false,
      width: 303,
      height: 20,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: 'wysiwyg.viewer.components.Group',
    components: [
      // @ts-expect-error
      {
        type: 'Component',
        layout: {
          x: 0,
          y: 10,
          fixedPosition: false,
          width: 93,
          height: 5,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        parent: 'comp-kholu0ul',
        props: {
          type: 'FiveGridLineProperties',
          fullScreenModeOn: false,
        },
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'line1',
            },
          ],
        },
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        style: {
          type: 'ComponentStyle',
          styleType: 'custom',
          style: {
            groups: {},
            properties: {
              'alpha-brd': '1',
              brd: 'color_15',
              lnw: '1px',
            },
            propertiesSource: {
              'alpha-brd': 'value',
              brd: 'theme',
              lnw: 'value',
            },
          },
        },
        mobileStructure: {
          layout: {
            width: 50,
            height: 5,
            x: 182,
            y: 9,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: 'FiveGridLineProperties',
            fullScreenModeOn: false,
            id: 'propItem-kjb869bx',
          },
        },
      },
      // @ts-expect-error
      {
        type: 'Component',
        layout: {
          x: 209,
          y: 10,
          fixedPosition: false,
          width: 93,
          height: 5,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        parent: 'comp-kholu0ul',
        props: {
          type: 'FiveGridLineProperties',
          fullScreenModeOn: false,
        },
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'line2',
            },
          ],
        },
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        style: {
          type: 'ComponentStyle',
          styleType: 'custom',
          style: {
            groups: {},
            properties: {
              'alpha-brd': '1',
              brd: 'color_15',
              lnw: '1px',
            },
            propertiesSource: {
              'alpha-brd': 'value',
              brd: 'theme',
              lnw: 'value',
            },
          },
        },
        mobileStructure: {
          layout: {
            width: 50,
            height: 5,
            x: 0,
            y: 9,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: 'FiveGridLineProperties',
            fullScreenModeOn: false,
          },
        },
      },
      // @ts-expect-error
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          x: 1,
          y: 0,
          fixedPosition: false,
          width: 302,
          height: 20,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        parent: 'comp-kholu0ul',
        data: {
          type: 'StyledText',
          text: `<p class="font_8" style="line-height:1.3em; text-align:center"><span style="letter-spacing:0.03em" class="color_15">${t(
            'components.socialLabel.label',
          )}</span></p>`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        props: {
          type: 'WRichTextProperties',
          brightness: 1,
          packed: true,
          verticalText: false,
          minWidth: null,
        },
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'socialDividerLabel',
            },
            {
              type: 'ConnectionItem',
              role: 'socialDividerLabel',
              isPrimary: true,
              controllerId: 'dataItem-kdy6319y',
            },
          ],
        },
        style: 'txtNew',
        mobileStructure: {
          layout: {
            width: 127,
            height: 20,
            x: 48,
            y: 0,
            scale: 0.75,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: 'WRichTextProperties',
            brightness: 1,
            packed: true,
            verticalText: false,
          },
        },
      },
    ],
    parent: 'comp-kholpa88',
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'group1',
        },
      ],
    },
    activeModes: {},
    mobileHints: {
      type: 'MobileHints',
      hidden: false,
    },
    mobileStructure: {
      layout: {
        x: 32,
        y: 405,
        width: 232,
        height: 20,
        rotationInDegrees: 0,
      },
      props: {
        isSocialLoginGoogleEnabled: true,
        isSmallIcons: true,
        isSocialLoginFacebookEnabled: true,
        mode: 'login',
        type: 'SocialAuthProperties',
      },
    },
  };
};

const socialComponent = (isResponsive: boolean): ComponentDefinition => ({
  type: 'Component',
  skin: 'wixui.skins.SocialAuth',
  layout: {
    width: 300,
    height: 63,
    x: 83.5,
    y: 498,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wixui.SocialAuth',
  data: {
    type: 'SocialAuth',
  },
  props: {
    isSocialLoginGoogleEnabled: true,
    isSmallIcons: true,
    isSocialLoginFacebookEnabled: true,
    formType: 'custom',
    mode: 'login',
    type: 'SocialAuthProperties',
  },
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'WixCodeConnectionItem',
        role: 'socialComponent',
      },
      {
        type: 'ConnectionItem',
        role: 'socialComponent',
        isPrimary: true,
        controllerId: 'dataItem-kdy6319y',
      },
    ],
  },
  style: {
    type: 'ComponentStyle',
    // @ts-expect-error
    style: {
      properties: {
        '$st-css':
          ":import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: SocialAuth;} .root { -st-extends: SocialAuth; background: red }",
      },
    },
    componentClassName: 'wixui.SocialAuth',
    styleType: 'custom',
    skin: 'wixui.skins.Skinless',
  },
  activeModes: {},
  mobileStructure: {
    layout: {
      width: 300,
      height: 63,
      x: 0,
      y: 441,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    props: {
      isSocialLoginGoogleEnabled: true,
      isSmallIcons: true,
      isSocialLoginFacebookEnabled: true,
      mode: 'login',
      type: 'SocialAuthProperties',
    },
  },
  layouts: isResponsive
    ? ({
        containerLayout: {},
        componentLayout: {
          type: 'ComponentLayout',
          hidden: false,
          height: {
            type: 'auto',
          },
          width: {
            value: 100,
            type: 'percentage',
          },
          minHeight: {
            type: 'px',
            value: 52,
          },
        },
        itemLayout: {
          alignSelf: 'end',
          margins: {
            left: {
              type: 'percentage',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 0,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 5,
            rowEnd: 6,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
        type: 'SingleLayoutData',
      } as any)
    : undefined,
});

export const requiredComponentsDefinitionMap = (
  t: TFunction,
  isResponsive: boolean,
) => {
  return {
    signUpLink: signUpLink(t, isResponsive),
    email: email(t, isResponsive),
    // emailErrMsg,
    password: password(t, isResponsive),
    // passwordErrMsg,
    forgotPasswordLink: forgotPasswordLink(t, isResponsive),
    generalErrMsg,
    submit: submit(t, isResponsive),
    // socialIframe,
    socialComponent: socialComponent(isResponsive),
  };
};

export const getCustomSignInDefinition = (
  appDefinitionId: string,
  controllerType: string,
  t: TFunction,
  isResponsive: boolean,
) => {
  return {
    type: 'Container',
    layout: {
      x: 20,
      y: 20,
      fixedPosition: false,
      width: 467,
      height: 609,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: 'platform.components.AppWidget',
    components: [
      {
        type: 'Container',
        id: 'comp-kdy631a0',
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
        layout: {
          x: 0,
          y: 0,
          fixedPosition: false,
          width: 467,
          height: 609,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: 'wysiwyg.viewer.components.FormContainer',
        components: [
          title(t, isResponsive),
          signUpLink(t, isResponsive),
          email(t, isResponsive),
          // emailErrMsg(t),
          password(t, isResponsive),
          // passwordErrMsg(t),
          forgotPasswordLink(t, isResponsive),
          generalErrMsg(t, isResponsive),
          submit(t, isResponsive),
          // horizontalLine,
          socialLabel(t, isResponsive),
          // socialDivider(t),
          // socialIframe,
          socialComponent(isResponsive),
        ],
        parent: 'comp-kdy630go',
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'formContainer',
            },
            {
              type: 'ConnectionItem',
              role: 'formContainer',
              isPrimary: true,
              controllerId: 'dataItem-kdy6319y',
            },
          ],
        },
        style: isResponsive
          ? {
              type: 'ComponentStyle',
              style: {
                properties: {
                  shd: '0px 0px 0px 0px rgba(0,0,0,0.6)',
                  rd: '0px',
                  'alpha-brd': '0',
                  'alpha-bg': '1',
                  bg: 'color_11',
                  brw: '0px',
                  'boxShadowToggleOn-shd': 'false',
                },
                propertiesSource: {
                  shd: 'value',
                  rd: 'value',
                  'alpha-brd': 'value',
                  'alpha-bg': 'value',
                  bg: 'theme',
                  brw: 'value',
                  'boxShadowToggleOn-shd': 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.FormContainer',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.FormContainerSkin',
            }
          : {
              type: 'ComponentStyle',
              style: {
                properties: {
                  loader: '0',
                },
                propertiesSource: {
                  loader: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.FormContainer',
              pageId: '',
              compId: 'comp-jrhw8ish',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.FormContainerSkin',
            },
        mobileStructure: {
          layout: {
            width: 300,
            height: 646,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
        },
        layouts: isResponsive ? {
          containerLayout: {
            type: 'GridContainerLayout',
            rows: [
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 80,
                },
                max: {
                  type: 'maxContent',
                },
              },
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 60,
                },
                max: {
                  type: 'maxContent',
                },
              },
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 50,
                },
                max: {
                  type: 'maxContent',
                },
              },
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 135,
                },
                max: {
                  type: 'maxContent',
                },
              },
              {
                type: 'MinMaxSize',
                min: {
                  type: 'px',
                  value: 80,
                },
                max: {
                  type: 'maxContent',
                },
              },
            ],
            columns: [
              {
                type: 'fr',
                value: 1,
              },
            ],
            rowGap: {
              type: 'px',
              value: 20,
            },
          },
          componentLayout: {
            type: 'ComponentLayout',
            height: {
              type: 'auto',
            },
            width: {
              type: 'percentage',
              value: 100,
            },
            minWidth: {
              type: 'px',
              value: 320,
            },
            hidden: false,
          },
          itemLayout: {
            alignSelf: 'stretch',
            margins: {
              left: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              columnStart: 1,
              rowEnd: 2,
              columnEnd: 2,
            },
            justifySelf: 'stretch',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        } : undefined,
      },
    ],
    parent: 'c1dmp',
    data: {
      type: 'AppController',
      applicationId: appDefinitionId,
      name: 'Widget1',
      controllerType,
      id: 'dataItem-kdy6319y',
    },
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'WixCodeConnectionItem',
          role: 'customLogin',
        },
      ],
    },
    style: 'appWidget1',
    activeModes: {},
    mobileStructure: {
      layout: {
        width: 300,
        height: 646,
        x: -10,
        y: 15,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
    },
    layouts: isResponsive
      ? {
          containerLayout: {
            type: 'GridContainerLayout',
            rows: [
              {
                value: 1,
                type: 'fr',
              },
            ],
            columns: [
              {
                type: 'fr',
                value: 1,
              },
            ],
            padding: {
              top: {
                type: 'px',
                value: 0,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
              left: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
            },
          },
          componentLayout: {
            minHeight: {
              type: 'px',
              value: 500,
            },
            hidden: false,
            height: {
              type: 'auto',
            },
            type: 'ComponentLayout',
            width: {
              type: 'percentage',
              value: 100,
            },
            maxWidth: {
              type: 'px',
              value: 320,
            },
          },
          itemLayout: {
            alignSelf: 'start',
            margins: {
              left: {
                type: 'px',
                value: 0,
              },
              right: {
                type: 'px',
                value: 0,
              },
              top: {
                type: 'px',
                value: 25,
              },
              bottom: {
                type: 'px',
                value: 0,
              },
            },
            gridArea: {
              rowStart: 1,
              rowEnd: 2,
              columnStart: 1,
              columnEnd: 2,
            },
            justifySelf: 'center',
            type: 'GridItemLayout',
          },
          type: 'SingleLayoutData',
        }
      : undefined,
  };
};

export const responsivePopupContainerDefinition: ResponsiveLayout = {
  type: 'LayoutData',
  id: '',
  metaData: undefined,
  componentLayouts: [
    {
      breakpoint: (undefined as unknown) as string,
      type: 'ComponentLayout',
      height: {
        type: 'auto',
      },
      width: {
        type: 'percentage',
        value: 28,
      },
      minHeight: {
        type: 'px',
        value: 200,
      },
      minWidth: {
        type: 'px',
        value: 320,
      },
      maxWidth: {
        type: 'px',
        value: 440,
      },
    },
  ],
  itemLayouts: [
    {
      breakpoint: (undefined as unknown) as string,
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      alignSelf: 'center',
      justifySelf: 'center',
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'percentage',
          value: 0,
        },
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
    },
  ],
  containerLayouts: [
    {
      breakpoint: (undefined as unknown) as string,
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
  ],
};
